import { BaseListenConfig, BaseStaticConfig, Boolean, Default, IFeatures, Json } from '@engineering11/config-web'

export interface AppFeatures extends IFeatures {}

export const defaultFeatures: AppFeatures = {}

export class AppConfig implements BaseStaticConfig {
  @Boolean()
  @Default(false)
  fromRemoteConfig!: boolean

  @Json()
  @Default(defaultFeatures)
  features!: AppFeatures
}

export interface AppListenConfig extends BaseListenConfig {}

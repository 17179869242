import * as userActions from './user/user.actions'
import * as userEffects from './user/user.effects'
import * as userReducer from './user/user.reducer'
import * as userSelectors from './user/user.selectors'

export { userActions, userEffects, userReducer, userSelectors }

export const sharedEffects = { ...userEffects }
export const sharedSelectors = { ...userSelectors }
export const sharedReducers = { user: userReducer }


<svg width="170px" height="64px" viewBox="0 0 170 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="green_leaf_collection" transform="translate(-2348.000000, -20.000000)">
            <g id="logo" transform="translate(2348.000000, 20.000000)">
                <rect id="Rectangle-Copy" fill="#D8D8D8" opacity="0" x="0" y="0" width="170" height="64"></rect>
                <g id="leaves" transform="translate(17.000000, 6.000000)" fill="#FFFFFF">
                    <path d="M28.7079257,23.8925955 C29.5571965,25.3891183 30.707729,26.6947651 31.8582615,28 C32.9169809,23.2863238 33.5467612,18.5116772 33.7662518,13.6947357 C34.2195788,18.451668 34.2798311,23.2202725 33.9484433,27.9821483 C39.7613581,19.3498838 39.280774,8.14642328 32.7548735,0 C32.401967,0.976214064 32.047626,1.95174152 31.6559858,2.90955459 C30.8153225,4.98419829 29.8469815,6.98564997 28.9847995,9.07732146 C27.8328324,11.896107 26.8802718,14.8802268 27.0122531,17.881649 C27.0854167,19.9724966 27.6793324,22.0714461 28.7079257,23.8925955" id="Fill-21"></path>
                    <path d="M13.9913405,19.6770113 C20.2554122,24.1188957 25.4675709,31.3228362 28.3670584,38.9551315 C28.1671821,21.0576804 15.9913846,5.4933563 0,4 C0.916099673,5.92214177 1.83219935,7.82590908 2.4817973,9.82284069 C3.64902359,13.4062929 3.96421313,17.2878996 4.83162499,21.0202137 C6.11416453,26.4887717 8.57930547,31.6396522 12.1424848,35.6330848 C14.3577803,38.1340224 17.0074225,40.1689949 19.8876911,41.3811357 C21.687859,42.1464036 23.5687461,42.5945684 25.4675709,43.0049794 C26.7847044,45.7485174 28.2004948,49.182677 29,52 C27.9173368,40.5410779 21.7544844,28.0939538 13.9913405,19.6770113" id="Fill-22" opacity="0.846469697"></path>
                    <path d="M39.9259259,32.1506395 C35.7592593,36.0508029 32.7777778,40.9123869 31.2407407,46.193945 C30.6851852,48.082887 30.3333333,50.0238885 30.1666667,52 C30.6851852,49.9190968 31.4814815,47.9249595 32.5,46.0189334 C34.2578348,45.7742399 36,45.4597302 37.5925926,44.794928 C39.8148148,43.8682138 41.7407407,42.2939162 43.2777778,40.4579754 C45.0925926,38.2717424 46.3148148,35.7531082 47.1866097,33.130355 C47.9074074,31.0139382 48.3888889,28.8102174 49.3518519,26.8168873 C49.8333333,25.8546596 50.4074074,24.9280799 51,24.000424 C39.7037037,23.9311457 30.3874644,32.3603575 30,43.0109124 C32.4444444,38.7611293 35.8148148,35.0535997 39.9259259,32.1506395" id="Fill-23" opacity="0.9"></path>
                </g>
                <text id="seed" opacity="0.898745582" font-family="Mona-Sans-Bold, Mona-Sans" font-size="33" font-weight="bold" fill="#FFFFFF">
                    <tspan x="70.2795" y="35">seed</tspan>
                </text>
                <text id="app---business" opacity="0.90162368" font-family="Aileron-SemiBold, Aileron" font-size="11.5" font-weight="500" fill="#FFFFFF">
                    <tspan x="72.2225" y="49">app - business</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IAuthService, ICustomClaims } from '@engineering11/auth-web'
import { UserType } from '@engineering11/user-shared'
import { IUserService } from '@engineering11/user-web'
import { E11Logger, ERROR_TRACKER_TOKEN, IErrorTracker } from '@engineering11/web-api-error'
import { Timestamp } from '@engineering11/web-api-firebase'
import { CookieService } from '@engineering11/web-utilities'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import * as Rollbar from 'rollbar'
import { from, of } from 'rxjs'
import { catchError, distinctUntilChanged, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { IAppUser } from '../../model/interfaces'
import { LocalStorageService } from '../../service/local-storage.service'
import { SessionStorageService } from '../../service/session-storage.service'
import {
  ErrorAction,
  NoAction,
  OnIdTokenChanged,
  OnInitUser,
  OnInitUserSuccess,
  OnLogIn,
  OnLogInSuccess,
  OnLogOut,
  OnLogOutSuccess,
  OnRegistration,
  OnUserDocumentChanged,
  UpdateUser,
  UpdateUserPhoto,
  UserActionTypes,
} from './user.actions'

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private localStorage: LocalStorageService,
    private cookieStorage: CookieService,
    private authService: IAuthService,
    private userService: IUserService,
    private sessionStorageService: SessionStorageService,
    private logger: E11Logger,
    public router: Router,
    @Inject(ERROR_TRACKER_TOKEN) private errorTracker: IErrorTracker
  ) {}

  ngrxOnInitEffects(): Action {
    return { type: UserActionTypes.onInitUser }
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OnInitUser>(UserActionTypes.onInitUser),
      mergeMap(action => this.authService.onIdTokenChange().pipe(map(token => new OnIdTokenChanged(token))))
    )
  })

  onInitUserDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnInitUser>(UserActionTypes.onInitUser),
      mergeMap(action => {
        return this.authService.onAuthStateChanged().pipe(
          mergeMap((user: firebase.default.User) => {
            return from(user.getIdTokenResult()).pipe(map(claim => new OnInitUserSuccess(claim.claims as ICustomClaims)))
          })
        )
      })
    )
  )

  onInitSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnInitUserSuccess>(UserActionTypes.onInitUserSuccess),
      mergeMap(action => {
        if (action.payload.appUserId) {
          return this.userService.getUserByIdValueChange(action.payload.appUserId).pipe(
            tap(response => {
              // this will kick the user out if it is disabled remotely
              if (response && response.isDisabled) {
                this.authService.signOut()
              }
            }),
            map((response: Timestamp<IAppUser> | undefined) => {
              const user = response ? response : null
              return new OnUserDocumentChanged(user)
            }),
            catchError(error => of(new ErrorAction(error)))
          )
        } else {
          return of(new NoAction())
        }
      })
    )
  )

  onIdTokenChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OnIdTokenChanged>(UserActionTypes.onIdTokenChanged),
        tap(action => {
          this.localStorage.setItem('authToken', action.payload)
          this.cookieStorage.setCookie({
            name: 'token',
            value: action.payload,
            expireDays: 30,
            secure: true,
          })
        })
      ),
    { dispatch: false }
  )

  onUserDocumentChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OnUserDocumentChanged>(UserActionTypes.onUserDocumentChanged),
        tap(action => this.localStorage.setItem('user', action.payload))
      ),
    { dispatch: false }
  )

  onUserIdChangedConfigureErrorTracker$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OnUserDocumentChanged>(UserActionTypes.onUserDocumentChanged),
        map(action => action.payload?.id),
        distinctUntilChanged(), // only call when the ID changes
        tap(userId => {
          // Needed for type safety and ensure we are using rollbar
          if (this.errorTracker instanceof Rollbar) {
            // casting as any to allow unsetting person see: https://docs.rollbar.com/docs/javascript#usage
            this.errorTracker.configure({ payload: { person: { id: userId ?? (null as any) } } })
          }
        })
      ),
    { dispatch: false }
  )

  onUpdateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateUser>(UserActionTypes.updateUser),
        switchMap(action => this.userService.update(action.payload))
      ),
    { dispatch: false }
  )

  onUpdateUserPhoto$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UpdateUserPhoto>(UserActionTypes.updateUserPhoto),
        // @ts-expect-error need to update user model so users can remove profile photos when desired
        switchMap(action => this.userService.update(action.payload))
      ),
    { dispatch: false }
  )

  onRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnRegistration>(UserActionTypes.onRegistration),
      map(action => {
        const referrerId = action.payload.queryParams['referrerId'] ?? this.sessionStorageService.getItem('referrerId')
        if (referrerId) {
          this.logger.log('Adding referrerId: ', referrerId)
          return new UpdateUser({ id: action.payload.appUserId, originSource: referrerId })
        }
        return new NoAction()
      })
      // map(response => new OnRegistrationSuccess())
    )
  )

  onLogIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnLogIn>(UserActionTypes.logIn),
      mergeMap(action => {
        this.localStorage.setItem('authToken', action.payload.token)
        return this.userService.getUserById(action.payload.appUserId).pipe(
          map((response: Timestamp<IAppUser> | undefined) => {
            this.localStorage.setItem('user', response)
            if (response) {
              return new OnLogInSuccess({ currentUser: response, redirectPath: action.payload.redirectPath })
            } else {
              return new ErrorAction(response)
            }
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OnLogInSuccess>(UserActionTypes.logInSuccess),
        mergeMap(action => {
          if (action.payload.currentUser.userType === UserType.Consumer) {
            this.router.navigate([action.payload.redirectPath || 'home'])
          } else {
            this.router.navigate([action.payload.redirectPath || 'home'])
          }
          return of(true)
        })
      ),
    { dispatch: false }
  )

  onLogOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType<OnLogOut>(UserActionTypes.logOut),
      mergeMap(action => {
        this.localStorage.clear()
        this.sessionStorageService.clear()
        this.localStorage.clear()
        this.cookieStorage.deleteCookie('token')
        return of(this.authService.signOut()).pipe(
          map(() => new OnLogOutSuccess(true)),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )
}

<router-outlet></router-outlet>

<lib-version-checker [environment]="_environment"></lib-version-checker>

<!-- <e11-side-over></e11-side-over> -->
<e11-notifications></e11-notifications>
<!-- <e11-modal-global></e11-modal-global> -->
<e11-loader-global></e11-loader-global>
<app-toast></app-toast>
<e11-side-over (sideOverClosed)="sideOverClose()"></e11-side-over>

import { InjectionToken } from '@angular/core'
import { IEnvironment } from './model/environment.model'
import { ErrorHandlingConfig } from './model/error-handling-config-model'

export enum COLLECTIONS {}

export enum SEARCH_INDICES {}

export enum InputsContext {
  Name = 'name',
  Description = 'description',
  LargeText = 'largeText',
}

export const INPUTS_MAXLENGTH_BY_CONTEXT: { [key in InputsContext]: number } = {
  name: 100,
  description: 200,
  largeText: 2000,
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('environment')
export const ERROR_HANDLING = new InjectionToken<ErrorHandlingConfig>('error_handling')

export enum ConversationTypes {
  DirectMessage = 'direct-message',
}

import { ErrorHandler, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/compat/auth'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { environment } from '@business/environments/environment'
import { FilesModule } from '@engineering11/files-web'
import { PermissionsModule } from '@engineering11/permissions-web'
import { RegistrationModule } from '@engineering11/registration-web'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11NotificationsModule } from '@engineering11/ui-lib/e11-notifications'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { E11ErrorModule, GlobalErrorHandler } from '@engineering11/web-api-error'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import firebase from 'firebase/compat/app'
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha'
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger'
import { componentStateReducer, errorHandlerOverrides, INPUTS_MAXLENGTH_BY_CONTEXT, WebpackTranslateLoader } from 'shared-lib'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AppFilesConfigProvider } from './config/files.config'
import { PERMISSION_TO_ROLE, UserRolesProvider } from './config/permission.config'
import { AuthenticationModule } from './modules/authentication/authentication.module'
import { E11ComponentLibraryModule } from './modules/_component-library/component-library.module'
import { SharedModule } from './modules/_shared/shared.module'
import { rollbarFactory } from './rollbar'
import * as fromStore from './store/index'

firebase.initializeApp(environment.firebaseConfig)
try {
  firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true })
} catch (e) {}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      defaultLanguage: 'en',
    }),
    AppRoutingModule,
    E11ComponentLibraryModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot({ ...fromStore.reducers, componentStore: componentStateReducer }),
    EffectsModule.forRoot(fromStore.effects),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    E11NotificationsModule.forRoot(),
    FilesModule.forRoot({ configProvider: AppFilesConfigProvider }),
    RecaptchaModule,
    LoggerModule.forRoot({ level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF, serverLogLevel: NgxLoggerLevel.OFF }),
    E11ErrorModule.forRoot({ errorTrackerFactory: rollbarFactory, logger: NGXLogger, errorHandlerMap: errorHandlerOverrides }),
    E11InputModule.forRoot({ maxLengthByContext: INPUTS_MAXLENGTH_BY_CONTEXT }),
    E11TextareaModule.forRoot({ maxLengthByContext: INPUTS_MAXLENGTH_BY_CONTEXT }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AuthenticationModule,
    RegistrationModule,
    PermissionsModule.forRoot({ userRolesProvider: UserRolesProvider, permissionToRole: PERMISSION_TO_ROLE }),

    // AuthModule,
    // E11ToastModule.forRoot(),
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.v2ReCaptcha } as RecaptchaSettings,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: TENANT_ID, useValue: environment.tenantId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { UserType } from '@engineering11/user-shared'

/**
 * Ensures user is a consumer, else throws an error
 * Should remain internal to store
 * @param user
 * @returns
 */
export const _consumerOrError = (user: any | null):  any | null => {
  if (!user) {
    return null
  }
  if (user.userType === UserType.Consumer) {
    return user
  }
  throw new Error(`User must be of type consumer, but is of type ${user.userType}`)
}

/**
 * Ensures user is a business user, else throws an error
 * Should remain internal to store
 * @param user
 * @returns
 */
export const _bussinessUserOrError = (user: any | null): any | null => {
  if (!user) {
    return null
  }
  if (user.userType === UserType.Business) {
    return user
  }
  throw new Error(`User must be of type business, but is of type ${user.userType}`)
}

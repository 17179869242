import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IExtendedINavigationItem } from './../nav.interface';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { TranslateModule } from '@ngx-translate/core';


@Component({
    standalone: true,
    selector: 'nav-item',
    imports: [CommonModule, TranslateModule, RouterModule],
    styleUrls: ['./nav-item.component.scss'],
    template: `
        <li
            (click)="onClick(nav)"
            class="e11-flex e11-items-center e11-cursor-pointer hover:e11-text-white"
            [ngClass]="{ 
                'e11-bg-skin-primary/70 e11-border-b-transparent ': nav.isOpen,
                'e11-h-10 e11-px-6 e11-truncate  hover:e11-bg-skin-primary-accent/30 e11-rounded-md e11-my-2': isChildren,
                'e11-h-12 e11-px-4 e11-justify-between': !isChildren,
                ' e11-border-b e11-border-b-skin-grey/30': nav.icon
            }"
            [routerLink]="nav.route"
            [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
        >
            <div class="e11-flex e11-items-center e11-w-full" >
                <span *ngIf="nav.icon" class="material-icons e11-mr-2 md-18"> {{nav.icon}} </span>
                <span class="e11-font-medium e11-text-md e11-relative"
                >{{ nav.name | translate }}</span>
            </div>
            <span *ngIf="nav.children?.length" class="material-icons-outlined e11-text-skin-extra-light nav-icon" [ngClass]="{ 'nav-icon-active': nav.isOpen}">
                expand_more
            </span>
        </li>

        <div *ngIf="nav.children && nav.children.length" class="nav-children-container e11-px-2" [ngClass]="{ 'nav-children-container-active': nav.isOpen }">
            <div class="e11-my-2">
                <nav-item  *ngFor="let child of nav.children" [nav]="child" [isChildren]="true"></nav-item>
            </div>
        </div>

    `,
   encapsulation: ViewEncapsulation.None,
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemComponent {
    @Input()
    isChildren: boolean = false

    @Input()
    nav!: IExtendedINavigationItem

    onClick(item: IExtendedINavigationItem) {
        if(item.children && item.children.length) {
            item.isOpen = !item.isOpen
            return
        }
    }
}
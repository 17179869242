<div
  aria-live="assertive"
  class="e11-fixed e11-inset-0 e11-flex e11-items-end e11-px-4 e11-py-6 e11-pointer-events-none sm:e11-p-6 sm:e11-items-start"
  [ngClass]="{ 'e11-z-50': showNewVersionWarning, 'e11--z-10': !showNewVersionWarning }"
>
  <div class="e11-w-full e11-flex e11-flex-col e11-items-center e11-space-y-4 sm:e11-items-end">
    <div
      class="e11-max-w-sm e11-w-full e11-bg-white e11-shadow-lg e11-rounded-lg e11-pointer-events-auto e11-ring-1 e11-ring-black e11-ring-opacity-5 e11-overflow-hidden"
      [ngClass]="{
        'e11-transform e11-ease-out e11-duration-300 e11-transition e11-translate-y-0 e11-opacity-100 sm:e11-translate-x-0': showNewVersionWarning,
        'e11-translate-y-2 e11-opacity-0 sm:e11-translate-y-0  sm:e11-translate-x-2': !showNewVersionWarning
      }"
    >
      <div class="e11-p-4">
        <div class="e11-flex e11-items-center">
          <div class="e11-w-0 e11-flex-1 e11-flex e11-items-center e11-justify-between">
            <p class="e11-w-0 e11-flex-1 e11-flex e11-items-center e11-text-sm e11-text-gray-900 e11-p-0 e11-m-0">
              <span class="material-icons-outlined e11-mr-1 e11-text-xl"> refresh </span>
              {{ 'There is a new version available.' | translate }}
            </p>
            <button
              type="button"
              class="e11-ml-3 e11-flex-shrink-0 e11-bg-white e11-rounded-md e11-text-sm e11-font-primary-demibold e11-text-skin-primary hover:e11-text-skin-primary focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2 focus:e11-ring-skin-primary e11-p-0"
              (click)="reload()"
            >
              {{ 'Get it' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

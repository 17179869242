import { Injectable } from '@angular/core'
import { Timestamp } from '@engineering11/web-api-firebase'
import { BaseLocalStorageService } from '@engineering11/web-utilities'
import { IAppUser } from '../model/interfaces'

export interface ILocalStorage {
  user: IAppUser | Timestamp<IAppUser> | null | undefined
  authToken: string
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends BaseLocalStorageService<ILocalStorage> {}

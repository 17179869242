export const buildServicePath = (service: string, projectId: string) => `https://${service}-rest-dot-${projectId}.uc.r.appspot.com`

export interface IServices {
  registration: string
  auth: string
}

export interface IPlatformAdminServices {
  auth: string
  user: string
}

export interface IConsumerServices extends IServices {
  user: string
  files: string
}
export interface IBusinessServices extends IServices {
  user: string
  files: string
  messaging: string
}

export interface IEnvironment {
  production: boolean
  env: string
  firebaseConfig: any
  services: IServices

  authentication: boolean
  themesEnabled: boolean
  v2ReCaptcha: string
  tenantId: string
  cdnCname: string
  algoliaAppId: string
  version: string
}

export interface IConsumerEnvironment extends IEnvironment {
  services: IConsumerServices
  consumerBaseUrl: string
}
export interface IBusinessEnvironment extends IEnvironment {
  services: IBusinessServices
}

export interface IPlatformAdminEnvironment {
  production: boolean
  env: string
  firebaseConfig: any
  services: IPlatformAdminServices
  authentication: boolean
  themesEnabled: boolean
  v2ReCaptcha: string
  tenantId: string
  cdnCname: string
  algoliaAppId: string
  version: string
}

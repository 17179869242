import { Injectable } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'

export const CONSUMER_ROUTES = Object.freeze({
  HOME: '/home',
  ACCOUNT: '/account',
  NOT_FOUND: '/404',
  LOGIN: '/auth/login',
  AUTH_RECOVER: '/auth/recover',
  REGISTRATION_REGISTER: '/registration/register',
  VERIFY_SUCCESS: '/actions/success/verifyEmail',
  PASSWORD_RESET_SUCCESS: '/actions/success/resetPassword',
})

@Injectable({ providedIn: 'root' })
export class ConsumerNavigationService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  toLogin() {
    this.router.navigate([CONSUMER_ROUTES.LOGIN])
  }

  toHome() {
    this.router.navigate([CONSUMER_ROUTES.HOME])
  }

  toRecover() {
    this.router.navigate([CONSUMER_ROUTES.AUTH_RECOVER])
  }

  addQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    })
  }

  updateQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    })
  }
}

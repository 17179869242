<div class="layout-app-admin">
    <div class="left e11-border-r e11-border-skin-app-borders">
        <!-- <app-nav-left></app-nav-left> -->
    </div>
    <div class="right">
        <div class="layout-content">
            <app-header></app-header>
            <div class="layout-router-outlet">
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer>
        </div>
    </div>
</div>
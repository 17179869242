import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ConfigModule } from '@engineering11/config-web'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { E11ToastModule } from '@engineering11/ui-lib/e11-toast'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { TranslateModule } from '@ngx-translate/core'
import { AccountManageNotificationsComponent } from './components/account-manage-notifications/account-manage-notifications.component'
import { NavItemComponent } from './components/side-nav/components/nav-item.component'
import { SideNavComponent } from './components/side-nav/side-nav.component'
import { ToastComponent } from './components/toast/toast.component'
import { VersionCheckerComponent } from './components/version-checker/version-checker.component'
import { AppConfig } from './config'
import { ENVIRONMENT, ERROR_HANDLING } from './constants'
import { IEnvironment, IPlatformAdminEnvironment } from './model/environment.model'
import { ErrorHandlingConfig } from './model/error-handling-config-model'
import { IdenticonPipe } from './pipes/identicon.pipe'
import { SharedLibComponent } from './shared-lib.component'
import { UiComponentLibraryModule } from './_component-library/component-library.module'

const DeclareExportComponents = [
  SharedLibComponent,
  VersionCheckerComponent,
  AccountManageNotificationsComponent,
  IdenticonPipe,
  ToastComponent,
  SideNavComponent,
]

@NgModule({
  declarations: [...DeclareExportComponents],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    WebUtilityModule,
    ConfigModule.forRoot(AppConfig),
    UiComponentLibraryModule,
    E11SwitchModule,
    E11PanelModule,
    E11ToastModule.forRoot(),
    NavItemComponent,
  ],
  exports: [...DeclareExportComponents],
})
export class SharedLibModule {
  public static forRoot(env: IEnvironment | IPlatformAdminEnvironment, errorHandling: ErrorHandlingConfig): ModuleWithProviders<SharedLibModule> {
    return {
      ngModule: SharedLibModule,
      providers: [
        { provide: ENVIRONMENT, useValue: env },
        { provide: ERROR_HANDLING, useValue: errorHandling },
      ],
    }
  }
}

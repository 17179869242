import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core'
import { E11Template } from '@engineering11/ui-lib/e11-ui-utils'
import { groupByKey } from '@engineering11/utility'
import { IExtendedINavigationItem, INavBottomTab, NavigationPositionType } from './nav.interface'

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, AfterContentInit, AfterViewInit {
  @ViewChild('navContainer') navContainer?: ElementRef

  e11Templates: any = {}
  @Input()
  set navigationList(value: IExtendedINavigationItem[]) {
    this.groupedNavigationItems = groupByKey(value, 'position' as any) as unknown as Record<NavigationPositionType, IExtendedINavigationItem[]>
  }
  navLeftBottomPos: number = 0
  groupedNavigationItems: Record<NavigationPositionType, IExtendedINavigationItem[]> = {
    top: [],
    middle: [],
    footer: [],
  }

  selectedTab: NavigationPositionType = 'top'

  @Input()
  mobileSideDrawerToggled = false

  @Output()
  mobileSideDrawerToggledChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input()
  mobileTabs: INavBottomTab[] = []

  @ContentChildren(E11Template)
  templates!: QueryList<any>
  navigationContainerTopPadding = 104 // Header height plus 1.5 rem

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.templates.forEach(item => {
      this.e11Templates[item.getType()] = item.template
    })
  }

  ngAfterViewInit(): void {
    let containerData = this.navContainer?.nativeElement.getBoundingClientRect()
    if (containerData) this.navLeftBottomPos = containerData.top + this.navContainer?.nativeElement.offsetHeight
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event: any[]) {
    if (this.navLeftBottomPos) {
      if (this.getFooterPos() - this.navLeftBottomPos < 104) {
        this.navigationContainerTopPadding = this.getFooterPos() - this.navLeftBottomPos
      } else {
        this.navigationContainerTopPadding = 104
      }
    }
  }

  getFooterPos() {
    let element = document?.getElementById('appFooter')
    return element!.getBoundingClientRect().top
  }
  close() {
    this.mobileSideDrawerToggledChange.emit(false)
  }
}

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { IUserRolesProvider } from '@engineering11/permissions-web'
import { getCurrentUser } from 'shared-lib'
import { Observable } from 'rxjs'
import { isNotNil } from '@engineering11/utility'
import { filter, map } from 'rxjs/operators'
import { AUTH_BASE_ROLES } from '@engineering11/auth-web'

export enum Permission {
  TEAM_MANAGE = 'TEAM_MANAGE',
  COMPANY_MANAGE = 'COMPANY_MANAGE',
}

export const PERMISSION_TO_ROLE = Object.freeze({
  [Permission.COMPANY_MANAGE]: [AUTH_BASE_ROLES.ADMINISTRATOR],
  [Permission.TEAM_MANAGE]: [AUTH_BASE_ROLES.ADMINISTRATOR],
})

@Injectable({ providedIn: 'root' })
export class UserRolesProvider implements IUserRolesProvider {
  constructor(private store: Store) {}
  getRoles(): Observable<string[]> {
    return this.store.select(getCurrentUser).pipe(
      filter(isNotNil),
      map(user => user.roles)
    )
  }
}

<h3 class="e11-text-skin-primary-accent e11-mb-4">{{ 'Notification Preferences' | translate }}</h3>
<e11-panel [hasBorder]="true" [hasShadow]="false" [contentClassOverrides]="'e11-p-6'">
  <div class="e11-w-full e11-flex e11-items-center">
    <e11-switch [size]="'md'" [checked]="userEmailEnabled" (changes)="toggleNotificationEmail(userEmailEnabled)"
  >
  </e11-switch>
    <div class="message-container">
      <div class="message" [ngClass]="{ 'on active': userEmailEnabled }">
        {{ 'Email notifications will be sent to the email associated with your account' | translate }}
      </div>
      <div class="message" [ngClass]="{ 'off active': !userEmailEnabled }">
        {{ 'Email notifications will NOT be sent to the email associated with your account' | translate }}
      </div>
    </div>
  </div>
</e11-panel>

import { Component } from '@angular/core'
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'
import { BusinessNavigationService, BUSINESS_ROUTES } from '@business/app/services/business-navigation.service'
import { UserService } from '@engineering11/user-web'
import { Store } from '@ngrx/store'
import jwt_decode from 'jwt-decode'
import { OnLogIn } from 'shared-lib'
import * as fromReduce from '../../../../store/reducers'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthenticationLoginComponent {
  BUSINESS_ROUTES = BUSINESS_ROUTES
  redirectPath?: string
  constructor(public router: Router, private store: Store<fromReduce.user.State>, private navigationService: BusinessNavigationService) {}

  onForgottenPassword() {
    this.navigationService.toRecover()
  }

  async onSubmit(token: string): Promise<void> {
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    if (primary && primary.segments.length > 1) {
      if (this.redirectPath === 'login') {
        this.redirectPath = 'home'
      }
      this.redirectPath = primary.segments[primary.segments.length - 1].path
    }
    const decoded: any = jwt_decode(token)
    this.store.dispatch(new OnLogIn({ token, appUserId: decoded.appUserId, redirectPath: this.redirectPath }))
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core'
import { IUser } from '@engineering11/user-shared'
import { IUserService, UserPreferencesService } from '@engineering11/user-web'
import { Subscription } from 'rxjs'
import { LocalStorageService } from '../../service/local-storage.service'

@Component({
  selector: 'account-manage-notifications',
  templateUrl: './account-manage-notifications.component.html',
  styleUrls: ['./account-manage-notifications.component.scss'],
})
export class AccountManageNotificationsComponent implements OnInit, OnDestroy {
  userEmailEnabled: boolean = false
  user?: IUser | undefined | null
  subscription: Subscription = new Subscription()
  constructor(private userService: IUserService, private localStorage: LocalStorageService, private userPreferencesService: UserPreferencesService) {}

  ngOnInit(): void {
    this.user = this.localStorage.getItem('user')
    if (this.user) {
      this.subscription = this.userPreferencesService.getUserPreferencesValueChanges(this.user.id).subscribe(userPreferences => {
        if (userPreferences) {
          this.userEmailEnabled = userPreferences.emailEnabled
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  toggleNotificationEmail(value: boolean) {
    if (this.user) {
      this.userPreferencesService.setUserPreference('emailEnabled', !value, this.user.id)
    }
  }

  async updateUser() {
    if (this.user) {
      await this.userService.update(this.user)
      this.localStorage.setItem('user', this.user)
    }
  }
}

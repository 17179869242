import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
// E11
import { E11AvatarModule } from '@engineering11/ui-lib/e11-avatar'
import { E11BackdropModule } from '@engineering11/ui-lib/e11-backdrop'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ChartPercentageModule } from '@engineering11/ui-lib/e11-chart-percentage'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { E11IndicatorModule } from '@engineering11/ui-lib/e11-indicator'
import { E11InputCheckboxModule } from '@engineering11/ui-lib/e11-input-checkbox'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11PopoverModule } from '@engineering11/ui-lib/e11-popover'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'
import { E11TextEditorModule } from '@engineering11/ui-lib/e11-text-editor'
import { E11TitleBarModule } from '@engineering11/ui-lib/e11-title-bar'

// Platform specific

const ImportExportModules = [
  E11AvatarModule,
  E11BackdropModule,
  E11BadgeModule,
  E11ButtonModule,
  E11ChartPercentageModule,
  E11EmptyStateModule,
  E11LoaderDotsModule,
  E11PanelModule,
  E11TitleBarModule,
  E11ProfilePicModule,
  E11RadioInputModule,
  E11TextEditorModule,
  E11ModalModule,
  E11PopoverModule,
  E11IndicatorModule,
  E11InputCheckboxModule,
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules],
  exports: [...ImportExportModules],
})
export class UiComponentLibraryModule {}

import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { NotificationConfigurationProvider, NotificationMappingConfig } from '@engineering11/notifications-web'
import { getCurrentUserId } from '../store/user/user.selectors'

@Injectable({ providedIn: 'root' })
export class ConfigurationStore {
  private internalConfig!: NotificationMappingConfig

  setConfig(config: NotificationMappingConfig) {
    this.internalConfig = config
  }

  getConfig() {
    return this.internalConfig
  }
}
@Injectable({ providedIn: 'root' })
export class AppNotificationConfigurationProvider implements NotificationConfigurationProvider {
  constructor(private configStore: ConfigurationStore) {}

  get config(): NotificationMappingConfig {
    return this.configStore.getConfig()
  }
}

export function initAppWithNotification(configurationStore: ConfigurationStore, store: Store) {
  return () => {
    configurationStore.setConfig({
      userId: store.pipe(getCurrentUserId),
    })
    return {}
  }
}

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FileNotFoundComponent } from '@business/app/components/_file-not-found/file-not-found.component'
import { AuthComponent, redirectLoggedInToHomeGuard, userCanAccessTermsPage } from '@engineering11/auth-web'
import { AppLayoutUnauthenticatedComponent } from '../../components/layouts/layout-unauthenticated/layout-unauthenticated.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthenticationRecoverComponent } from './views/recover/recover.component'

const routes: Routes = [
  {
    path: '',
    component: AppLayoutUnauthenticatedComponent,
    children: [
      {
        path: '',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'login',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'login/:deepLink',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'recover',
        component: AuthenticationRecoverComponent,
      },
      { path: '**', redirectTo: '404' },
      {
        path: '404',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}

import { Component } from '@angular/core'
import { BUSINESS_ROUTES } from '@business/app/services/business-navigation.service'
import { IExtendedINavigationItem, INavBottomTab } from 'shared-lib'

@Component({
  selector: 'app-layout-authenticated',
  templateUrl: './layout-authenticated.component.html',
  styleUrls: ['./layout-authenticated.component.scss'],
})
export class AppLayoutAuthenticatedComponent {
  navigationList: IExtendedINavigationItem[] = [
    {
      id: 'home',
      name: 'Home',
      route: BUSINESS_ROUTES.HOME,
      icon: 'home',
      position: 'top',
    },
    {
      id: 'account',
      name: 'Account',
      route: BUSINESS_ROUTES.ACCOUNT,
      icon: 'lock',
      position: 'middle',
    },
    {
      id: 'company-settings',
      name: 'Company Settings',
      isOpen: false,
      position: 'middle',
      // customTemplateKey: 'message',
      children: [
        {
          id: 'account-information',
          name: 'Account Information',
          route: '/customer/account-information',
        },
        {
          id: 'company-logo',
          name: 'Company Logo',
          route: '/customer/company-logo',
        },
        {
          id: 'locations',
          name: 'Locations',
          route: '/customer/locations',
        },
        {
          id: 'departments',
          name: 'Departments',
          route: '/customer/departments',
        },
        {
          id: 'manage-users',
          name: 'Manage Users',
          route: '/users',
        },
      ],
    },
    {
      id: 'info_tips',
      name: 'Info and Tips',
      icon: 'tips_and_updates',
      position: 'footer',
    },
  ]

  mobileTabs: INavBottomTab[] = [
    {
      id: 'home',
      name: 'Home',
      routeGroup: 'top',
      icon: 'home',
    },
    {
      id: 'messages',
      name: 'Messages',
      routeGroup: 'middle',
      icon: 'chat',
    },
  ]
  mobileDrawerState = false
  constructor() {}
}

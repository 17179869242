
import { NgModule } from '@angular/core'
import { canActivate } from '@angular/fire/compat/auth-guard'
import { RouterModule, Routes } from '@angular/router'
import { loggedInOnlyGuard } from '@engineering11/auth-web'
import { AppComponent } from './app.component'

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    // ...redirectLoggedInToHomeGuard(),
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'actions',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/email-actions/email-actions.module').then(m => m.EmailActionsModule),
      },
    ],
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'customer',
    loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
  },
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: '/home',
  // },
  // { path: '**', redirectTo: '404' },
  // {
  //   path: '404',
  //   loadChildren: () => import('./modules/_file-not-found/file-not-found.module').then(m => m.AppFileNotFoundModule),
  // },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 120],
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      urlUpdateStrategy: 'eager',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

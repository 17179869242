// Framework
import { Injectable } from '@angular/core'
import { BusinessSizeRange, GICSIndustryGroup, IndustrySector } from '@engineering11/customer-web'
import { TranslateService } from '@ngx-translate/core'
// Platform specific
import { FormSelectModel } from '../model/form-select.model'

@Injectable({
  providedIn: 'root',
})
export class FormSelectService {
  pleaseSelect = ''
  selectLevel = ''
  pleaseSelectState: string = ' ' // to be translated if needed

  constructor(private translate: TranslateService) {
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelectState = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelect = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.selectLevel = res
    })
  }

  getSelectMonth() {
    return [
      new FormSelectModel('01', 'Jan'),
      new FormSelectModel('02', 'Feb'),
      new FormSelectModel('03', 'Mar'),
      new FormSelectModel('04', 'Apr'),
      new FormSelectModel('05', 'May'),
      new FormSelectModel('06', 'Jun'),
      new FormSelectModel('07', 'Jul'),
      new FormSelectModel('08', 'Aug'),
      new FormSelectModel('09', 'Sep'),
      new FormSelectModel('10', 'Oct'),
      new FormSelectModel('11', 'Nov'),
      new FormSelectModel('12', 'Dec'),
    ]
  }

  getAddressStates() {
    return [
      new FormSelectModel('', this.pleaseSelectState),
      new FormSelectModel('AL', 'AL'),
      new FormSelectModel('AK', 'AK'),
      new FormSelectModel('AZ', 'AZ'),
      new FormSelectModel('AR', 'AR'),
      new FormSelectModel('CA', 'CA'),
      new FormSelectModel('CO', 'CO'),
      new FormSelectModel('CT', 'CT'),
      new FormSelectModel('DE', 'DE'),
      new FormSelectModel('DC', 'DC'),
      new FormSelectModel('FL', 'FL'),
      new FormSelectModel('GA', 'GA'),
      new FormSelectModel('HI', 'HI'),
      new FormSelectModel('ID', 'ID'),
      new FormSelectModel('IL', 'IL'),
      new FormSelectModel('IN', 'IN'),
      new FormSelectModel('IA', 'IA'),
      new FormSelectModel('KS', 'KS'),
      new FormSelectModel('KY', 'KY'),
      new FormSelectModel('LA', 'LA'),
      new FormSelectModel('ME', 'ME'),
      new FormSelectModel('MD', 'MD'),
      new FormSelectModel('MA', 'MA'),
      new FormSelectModel('MI', 'MI'),
      new FormSelectModel('MN', 'MN'),
      new FormSelectModel('MS', 'MS'),
      new FormSelectModel('MO', 'MO'),
      new FormSelectModel('MT', 'MT'),
      new FormSelectModel('NE', 'NE'),
      new FormSelectModel('NV', 'NV'),
      new FormSelectModel('NH', 'NH'),
      new FormSelectModel('NJ', 'NJ'),
      new FormSelectModel('NM', 'NM'),
      new FormSelectModel('NY', 'NY'),
      new FormSelectModel('NC', 'NC'),
      new FormSelectModel('ND', 'ND'),
      new FormSelectModel('OH', 'OH'),
      new FormSelectModel('OK', 'OK'),
      new FormSelectModel('OR', 'OR'),
      new FormSelectModel('PA', 'PA'),
      new FormSelectModel('RI', 'RI'),
      new FormSelectModel('SC', 'SC'),
      new FormSelectModel('SD', 'SD'),
      new FormSelectModel('TN', 'TN'),
      new FormSelectModel('TX', 'TX'),
      new FormSelectModel('UT', 'UT'),
      new FormSelectModel('VT', 'VT'),
      new FormSelectModel('VA', 'VA'),
      new FormSelectModel('WA', 'WA'),
      new FormSelectModel('WV', 'WV'),
      new FormSelectModel('WI', 'WI'),
      new FormSelectModel('WY', 'WY'),
    ]
  }

  getMarketSectors() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(enumToFormSelectModelArray(IndustrySector))
  }

  getIndustryGroups() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(GICSIndustryGroup.map(value => new FormSelectModel(value, value)))
  }

  getBusinessSizesList() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(BusinessSizeRange.map(value => new FormSelectModel(value, value)))
  }
  getBusinessTypes() {
    return [new FormSelectModel('', this.pleaseSelectState), new FormSelectModel('Public', 'Public'), new FormSelectModel('Private', 'Private')]
  }

  
}

function enumToFormSelectModelArray(enumerator: any) {
  return Object.keys(enumerator).map(key => new FormSelectModel(key, enumerator[key]))
}

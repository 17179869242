<div class="e11-hidden lg:e11-flex app-nav-left e11-w-full">
  <div
    #navContainer
    class="e11-bg-skin-app-bg-dark e11-text-white/70 e11-overflow-hidden e11-rounded nav-prototype"
    [ngStyle]="{ top: navigationContainerTopPadding + 'px' }"
  >
    <!-- Top -->
    <ng-container *ngFor="let nav of groupedNavigationItems.top">
      <div class="nav-top">
        <nav-item *ngIf="!nav.customTemplateKey" [nav]="nav"></nav-item>
        <ng-container *ngIf="nav.customTemplateKey">
          <ng-container *ngTemplateOutlet="e11Templates[nav.customTemplateKey]"></ng-container>
        </ng-container>
      </div>
    </ng-container>
    <!-- Middle -->
    <div
      class="nav-middle e11-relative e11-overscroll-contain e11-overflow-y-scroll e11-scrollbar-thumb-blue e11-scrollbar-thumb-rounded e11-scrollbar-track-blue-lighter e11-scrollbar-w-2 e11-scrolling-touch"
    >
      <div class="bg e11-absolute"></div>
      <ng-container *ngFor="let nav of groupedNavigationItems.middle">
        <nav-item *ngIf="!nav.customTemplateKey" [nav]="nav"></nav-item>
        <ng-container *ngIf="nav.customTemplateKey">
          <ng-container *ngTemplateOutlet="e11Templates[nav.customTemplateKey]"></ng-container>
        </ng-container>
      </ng-container>
    </div>
    <!-- bottom -->
    <div
      class="nav-bottom e11-flex e11-items-center e11-h-12 e11-px-4 e11-bg-skin-app-bg-dark e11-font-bold hover:e11-bg-skin-black e11-cursor-pointer e11-border-t e11-border-t-skin-grey/30"
    >
      <ng-container *ngFor="let nav of groupedNavigationItems.footer">
        <nav-item *ngIf="!nav.customTemplateKey" [nav]="nav"></nav-item>
        <ng-container *ngIf="nav.customTemplateKey">
          <ng-container *ngTemplateOutlet="e11Templates[nav.customTemplateKey]"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div
  class="e11-block lg:e11-hidden mobile-drawer-container e11-w-full e11-fixed e11-top-[78px] e11-left-0 e11-bottom-0 e11-z-20"
  [ngClass]="{ 'side-drawer-active': mobileSideDrawerToggled }"
>
  <div
    class="mobile-drawer e11-top-0 e11-w-[90vw] e11-fixed e11-bottom-0 e11-z-20 e11-divide-y e11-divide-gray-200 e11-flex e11-flex-col e11-shadow-xl"
  >
    <!-- content-->
    <div class="e11-bg-skin-primary e11-text-white e11-flex-1 e11-h-0 e11-overflow-y-auto e11-overscroll-none e11-pt-5">
      <ng-container *ngFor="let tab of mobileTabs">
        <ng-container *ngIf="tab.routeGroup === selectedTab">
          <div *ngFor="let nav of groupedNavigationItems[tab.routeGroup]" class="e11-px-4">
            <div>
              <nav-item *ngIf="!nav.customTemplateKey" [nav]="nav"></nav-item>
              <ng-container *ngIf="nav.customTemplateKey">
                <ng-container *ngTemplateOutlet="e11Templates[nav.customTemplateKey]"></ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <!-- Tabs-->
    <div class="e11-text-skin-light e11-flex e11-justify-around e11-p-4 e11-flex-shrink-0 e11-bg-skin-primary">
      <ng-container *ngFor="let tab of mobileTabs">
        <div
          (click)="selectedTab = tab.routeGroup"
          class="e11-flex e11-flex-col e11-items-center"
          [ngClass]="{ 'e11-text-white': selectedTab === tab.routeGroup }"
        >
          <span class="icon material-icons">{{ tab.icon }}</span>
          <span>{{ tab.name | translate }}</span>
          <!-- <e11-badge
                    *ngIf="unreadConversationCount$ | async"
                    [value]="(unreadConversationCount$ | async) ?? 0"
                    [color]="'error'"
                    [size]="'sm'"
                    [posAdjustX]="'-32px'"
                    [posAdjustY]="'-12px'"
                    [limit]="UNREAD_LIMIT"
                    [containerClassOverrides]="''"
                ></e11-badge> -->
        </div>
      </ng-container>

      <div (click)="close()" class="e11-flex e11-flex-col e11-items-center">
        <span class="icon material-icons material-icons-outlined">arrow_back_ios</span>
        <span>{{ 'Close' | translate }}</span>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core'
import { BusinessNavigationService } from '@business/app/services/business-navigation.service'

@Component({
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class AuthenticationRecoverComponent {
  constructor(private navigationService: BusinessNavigationService) {}

  toLogin() {
    this.navigationService.toLogin()
  }

  toHome() {
    this.navigationService.toHome()
  }
}

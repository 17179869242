import { Inject, Pipe, PipeTransform } from '@angular/core'
import { IdenticonService } from '@engineering11/registration-web'
import { ENVIRONMENT } from '../constants'
import { IEnvironment } from '../model/environment.model'

@Pipe({ name: 'toIdenticon' })
export class IdenticonPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private environment: IEnvironment) {}
  transform(userId: string): string {
    return IdenticonService.buildPath(userId, this.environment.cdnCname)
  }
}

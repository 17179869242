import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core'
import { CONSUMER_ROUTES } from '@consumer/app/services/consumer-navigation.service'
import { ConfigStore } from '@engineering11/config-web'
import { INavigationItem } from '@engineering11/ui-lib/e11-nav-left'
import { isNotNil } from '@engineering11/utility'
import { ViewportService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { selectors } from 'projects/app-consumer/src/app/store/selectors'
import { AppFeatures } from 'projects/shared-lib/src/lib/config'
import { Observable } from 'rxjs/internal/Observable'
import { filter, map } from 'rxjs/operators'
import { IAppConsumerUser, OnLogOut } from 'shared-lib'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent {
  dropDownOpen = false
  currentUser$: Observable<IAppConsumerUser | null> = this.store.select(selectors.getCurrentUser)
  features$: Observable<AppFeatures | undefined> = this.configStore.features$
  features?: AppFeatures
  navigationItems: INavigationItem[] = []
  CONSUMER_ROUTES = CONSUMER_ROUTES

  @Input()
  mobileDrawerState = false

  @Output()
  mobileDrawerStateChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'sm' || data == 'xs'),
    filter(isNotNil)
  )
  @Output()
  mobileSideDrawerToggle: EventEmitter<boolean> = new EventEmitter(false)

  constructor(private viewportService: ViewportService, private store: Store, private elementRef: ElementRef, private configStore: ConfigStore) {}

  signOut(): void {
    this.store.dispatch(new OnLogOut())
  }

  onClickAccount() {
    this.dropDownOpen = !this.dropDownOpen
  }

  toggleMobileSideDrawer() {
    this.mobileDrawerStateChange.emit(!this.mobileDrawerState)
  }

  // Closers - Coffee is for closers only
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any[]) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      this.dropDownOpen = false
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dropDownOpen = false
    }
  }
}

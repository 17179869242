<div class="layout-app">
    <app-header [(mobileDrawerState)]="mobileDrawerState"></app-header>
    <div class="layout-main e11-px-2 md:e11-px-6">
        <div class="layout-nav-left ">
            <side-nav [navigationList]="navigationList" [mobileTabs]="mobileTabs" [(mobileSideDrawerToggled)]="mobileDrawerState"></side-nav>
        </div>
        <div class="layout-content  e11-min-h-screen">
            <router-outlet></router-outlet>
        </div>
      </div>
    <app-footer></app-footer>
</div>
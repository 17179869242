import { Component, OnInit } from '@angular/core'
import { environment } from '@business/environments/environment'
import { NotificationStore } from '@engineering11/notifications-web'
import { UiScrollLockingService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { hookToGlobalState } from 'shared-lib'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app-business'
  _environment = environment

  constructor(private scrollLockService: UiScrollLockingService, private notificationStore: NotificationStore, private store: Store) {}
  sideOverClose() {
    this.scrollLockService.scrollLock(false)
  }

  ngOnInit(): void {
    hookToGlobalState(this.notificationStore.getState, 'notification', this.store)
  }
}

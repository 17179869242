import { CommonModule } from '@angular/common'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ERROR_HANDLING } from '@business/app/app.constants'
import { AppLogoBlackComponent } from '@business/app/components/logo-black/logo.component'
import { AppLogoColorComponent } from '@business/app/components/logo-color/logo.component'
import { AppLogoDefaultComponent } from '@business/app/components/logo-default/logo.component'
import { AppLogoWhiteComponent } from '@business/app/components/logo-white/logo.component'
import { ConversationCustomDataProvider, MessagingConfigFromApp } from '@business/app/config/messaging.config'
import { environment } from '@business/environments/environment'
import { DirectMessageConversationConfigProvider, MessagingConfigurationProvider, MessagingModule } from '@engineering11/messaging-web'
import {
  NotificationConfigurationProvider,
  NotificationHandlerModule,
  UiNotificationComponent,
  UiNotificationLongComponent
} from '@engineering11/notifications-web'
import { E11NotificationsModule } from '@engineering11/ui-lib/e11-notifications'
import { E11ToastModule } from '@engineering11/ui-lib/e11-toast'
import { Store } from '@ngrx/store'
import { TranslateModule } from '@ngx-translate/core'
import { AppNotificationConfigurationProvider, ConfigurationStore, DefaultNotificationHandler, initAppWithNotification, SharedLibModule } from 'shared-lib'
import { AppFooterComponent } from '../../components/footer/footer.component'
import { AppHeaderUnAuthComponent } from '../../components/header/header-unauth.component'
import { AppHeaderComponent } from '../../components/header/header.component'
import { AppLayoutAdminComponent } from '../../components/layouts/layout-admin/layout-admin.component'
import { AppLayoutAuthenticatedComponent } from '../../components/layouts/layout-authenticated/layout-authenticated.component'
import { AppLayoutUnauthenticatedComponent } from '../../components/layouts/layout-unauthenticated/layout-unauthenticated.component'
import { FileNotFoundComponent } from '../../components/_file-not-found/file-not-found.component'
import { E11ComponentLibraryModule } from '../_component-library/component-library.module'

const DeclarationsAndExports = [
  AppHeaderComponent,
  AppHeaderUnAuthComponent,
  AppFooterComponent,
  FileNotFoundComponent,
  AppLayoutAuthenticatedComponent,
  AppLayoutUnauthenticatedComponent,
  AppLayoutAdminComponent,
  AppLogoBlackComponent,
  AppLogoColorComponent,
  AppLogoDefaultComponent,
  AppLogoWhiteComponent
]

@NgModule({
  declarations: [...DeclarationsAndExports],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    E11ComponentLibraryModule,
    E11NotificationsModule.forRoot(),
    E11ToastModule.forRoot(),
    SharedLibModule.forRoot(environment, ERROR_HANDLING),
    NotificationHandlerModule.forRoot({
      notificationHandlers: [
        DefaultNotificationHandler
      ],
      config: {
        provide: NotificationConfigurationProvider,
        useClass: AppNotificationConfigurationProvider,
      },
    }),
    MessagingModule.forRoot({
      config: {
        provide: MessagingConfigurationProvider,
        useClass: MessagingConfigFromApp,
      },
      conversationCustomDataProvider: ConversationCustomDataProvider,
      cacheDBName: 'seed-storage',
      conversationConfigProviders: [
        DirectMessageConversationConfigProvider,
      ],
    }),
  ],
  exports: [E11ComponentLibraryModule, ...DeclarationsAndExports, SharedLibModule, UiNotificationComponent,
    UiNotificationLongComponent,],
    providers: [
      {
        provide: APP_INITIALIZER,
        useFactory: initAppWithNotification,
        multi: true,
        deps: [ConfigurationStore, Store], // <-- Add this
      },
    ]
})
export class SharedModule {}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ICustomer, ICustomerDepartment, ICustomerLocation } from '@engineering11/customer-web'

import { CustomerActions, CustomerActionTypes } from './customer.actions'
import { IAppBusinessUser } from 'shared-lib'

export interface State extends EntityState<ICustomer> {
  loaded: boolean
  loading: boolean
  error: [] | null
  departments: ICustomerDepartment[]
  locations: ICustomerLocation[]
  users: IAppBusinessUser[]
}

export const adapter: EntityAdapter<ICustomer> = createEntityAdapter<ICustomer>()

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  departments: [],
  locations: [],
  users: [],
})

export function reducer(state = initialState, action: CustomerActions): State {
  switch (action.type) {
    case CustomerActionTypes.get:
    case CustomerActionTypes.getDepartment:
    case CustomerActionTypes.getLocation:
      return {
        ...state,
        loading: true,
      }
    case CustomerActionTypes.getDepartmentSuccess:
      return {
        ...state,
        departments: action.payload,
      }
    case CustomerActionTypes.getLocationSuccess:
      return {
        ...state,
        locations: action.payload,
      }
    case CustomerActionTypes.getCustomerUsersSuccess:
      return {
        ...state,
        users: action.payload,
      }
    default:
      return state
  }
}

export const customerEntitySelectors = adapter.getSelectors()

import { Injectable } from '@angular/core'
import { environment } from '@business/environments/environment'
import {
  BaseConversationCustomDataProvider,
  DEFAULT_CONVERSATION_TYPE,
  IConversationVMNoCustomData,
  IParticipantInformation,
  MessagingConfigurationProvider,
  MessagingMappingConfig,
} from '@engineering11/messaging-web'
import { Store } from '@ngrx/store'
import { ConversationTypes, getCurrentToken, getCurrentUserId } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class MessagingConfigurationStore {
  constructor(private store: Store) {}

  getConfig() {
    return {
      baseUrl: environment.services.messaging,
      baseRoute: '/c/m',
      token$: this.store.select(getCurrentToken),
      userId$: this.store.pipe(getCurrentUserId),
      onConversationDeleteRoute: '/network/cnections',
    }
  }
}

@Injectable({ providedIn: 'root' })
export class MessagingConfigFromApp implements MessagingConfigurationProvider {
  constructor(private configStore: MessagingConfigurationStore) {}

  get config(): MessagingMappingConfig {
    return this.configStore.getConfig()
  }
}

@Injectable({ providedIn: 'root' })
export class ConversationCustomDataProvider extends BaseConversationCustomDataProvider {
  conversationShortName(conversation: IConversationVMNoCustomData): string {
    switch (conversation.type ?? DEFAULT_CONVERSATION_TYPE) {
      default:
        return super.conversationShortName(conversation)
    }
  }
  conversationName(conversation: IConversationVMNoCustomData): string {
    switch (conversation.type ?? DEFAULT_CONVERSATION_TYPE) {
      default:
        return super.conversationShortName(conversation)
    }
  }
  conversationSubtitle(conversation: IConversationVMNoCustomData): string {
    const type: ConversationTypes | undefined = conversation.type as ConversationTypes | undefined

    switch (type) {
      case ConversationTypes.DirectMessage:
        return ''
      default:
        return ''
    }
  }

  amendParticipantInformation(participantInfo: IParticipantInformation, conversationType: string): IParticipantInformation {
    const type: ConversationTypes | undefined = conversationType as ConversationTypes | undefined

    switch (type) {
      case ConversationTypes.DirectMessage:
      default:
        return participantInfo
    }
  }
}

const localeCompareFn = (a: string, b: string) => a.localeCompare(b)

function amendSocialParticipantInfo(participantInfo: IParticipantInformation): IParticipantInformation {
  const { firstName, lastName, displayName } = participantInfo
  const firstLast = `${firstName} ${lastName}`
  if (firstLast === displayName) return { ...participantInfo, displayName: firstLast }
  else return { ...participantInfo, displayName: firstLast, secondaryName: `@${displayName}` }
}

import { FILES_ERRORS } from '@engineering11/files-web'
import { IErrorTypeHandlerMap, TrackErrorE11ErrorHandler, TrackWarningE11ErrorHandler } from '@engineering11/web-api-error'
import { NotificationE11ErrorHandler } from './notification-error.handler'

export const APP_ERRORS = Object.freeze({})

export const errorHandlerOverrides: IErrorTypeHandlerMap = {}

// ? Assuming the user needs to be notified of any file-related error
Object.values(FILES_ERRORS).forEach(value => (errorHandlerOverrides[value] = NotificationE11ErrorHandler))

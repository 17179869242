// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { buildServicePath, IBusinessEnvironment } from 'projects/shared-lib/src/lib/model/environment.model'

const projectId = 'e11-sandbox'

export const environment: IBusinessEnvironment = {
  production: false,
  env: '',
  firebaseConfig: {
    apiKey: 'AIzaSyA_DASeEdtp13oxxF4yBclyDW2AYSN-yGY',
    authDomain: 'e11-sandbox.firebaseapp.com',
    databaseURL: 'https://e11-sandbox.firebaseio.com',
    projectId: 'e11-sandbox',
    storageBucket: 'e11-sandbox.appspot.com',
    messagingSenderId: '104625134116',
    appId: '1:104625134116:web:666af6f53de1b33cc3d6b1',
    measurementId: 'G-YY489XF21K',
  },
  services: {
    user: buildServicePath('user', projectId),
    auth: buildServicePath('auth', projectId),
    registration: buildServicePath('registration', projectId),
    files: buildServicePath('files', projectId),
    messaging: buildServicePath('messaging', projectId),
  },
  authentication: false,
  themesEnabled: false,
  v2ReCaptcha: '6LdJbnolAAAAAJMq75ov0taTaTQrKCXcdPm_FGet',
  tenantId: 'business-ctn-9m3wo',
  cdnCname: 'dev.content.engineering11.com',
  algoliaAppId: 'V6D9QM641U',
  version: '',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { DefaultAppNotification } from '../../model/app-notification.model'

@Injectable({ providedIn: 'root' })
export class DefaultNotificationHandler implements INotificationHandler<DefaultAppNotification, INotificationView> {
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}
  notificationType = 'candidate-applied'
  toViewModel(notification: DefaultAppNotification): INotificationView {
    return {
      description: `This is a default notification`,
      logoUrl: '',
      originalNotification: notification,
      title: 'New Application Received',
      viewed: notification.viewed,
    }
  }

  onClick(notification: DefaultAppNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['/notification'])
  }
}

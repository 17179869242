<div class="view e11-flex e11-flex-col e11-justify-center">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <h2 class="e11-text-center e11-mb-2 e11-text-skin-primary">
      {{ 'Sign into your account' | translate }}
    </h2>
    <p class="e11-text-center">
      {{ 'Not signed up?' | translate }}
      <a [routerLink]="[BUSINESS_ROUTES.REGISTRATION_REGISTER]" class="e11-cursor-pointer e11-text-skin-secondary hover:e11-text-skin-secondary">
        {{ 'Create an account' | translate }}
      </a>
    </p>
  </div>

  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <e11-panel [hasBorder]="true" [hasShadow]="false" [bgOpacity]="90" [contentClassOverrides]="'e11-p-6'">
      <auth-login (onSubmit)="onSubmit($event)" (onForgottenPassword)="onForgottenPassword()"></auth-login>
    </e11-panel>
  </div>
</div>

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AuthComponent } from './authentication.component'
import { AuthenticationRoutingModule } from './authentication-routing.module'
import { SharedModule } from '../_shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AuthModule, IAuthConfig } from '@engineering11/auth-web'
import { UserModule } from '@engineering11/user-web'
import { RecaptchaModule } from 'ng-recaptcha'
import { environment } from '../../../environments/environment'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthenticationRecoverComponent } from './views/recover/recover.component'
import { TranslateModule } from '@ngx-translate/core'

const authConfig: IAuthConfig = {
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: '',
  tenantId: environment.tenantId,
}

@NgModule({
  declarations: [AuthComponent, AuthenticationLoginComponent, AuthenticationRecoverComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    AuthModule.forRoot(authConfig),
    UserModule,
  ],
})
export class AuthenticationModule {}
